import icon from './icon.png';
import icon_svg from './icon.svg';
import './App.css';
import {useEffect, useState} from "react";

import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago';

import en from 'javascript-time-ago/locale/en'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faDownload, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {saveAs} from 'file-saver'

TimeAgo.addDefaultLocale(en)

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({});
  const [postURL, setPostURL] = useState('');
  const [author, setAuthor] = useState({});
  const [showTags, setShowTags] = useState(false);

  const getPost = async () => {
    const response = await fetch('/api/random');
    return await response.json();
  }

  const getAuthor = async (id) => {
    const response = await fetch(`https://e621.net/users/${id}.json`);
    return await response.json();
  }

  useEffect(() => {
    const getPosts = async () => {
      const postsResp = await getPost();
      setPost(postsResp);
    };

    getPosts();
  }, []);

  useEffect(() => {
    //   Convert MD5 hash to e621 URL
    if (post?.md5) {
      setPostURL(`https://static1.e621.net/data/${post.md5.slice(0, 2)}/${post.md5.slice(2, 4)}/${post.md5}.${post.file_ext}`);
    }
    if (post?.uploader_id) {
      const authorResp = getAuthor(post.uploader_id);
      authorResp.then((author) => {
        setAuthor(author);
      });
    }
    setIsLoading(false);
  }, [post]);

  const handleNext = async () => {
    setIsLoading(true);
    setPost({});
    setPostURL('');
    const postsResp = await getPost();
    setPost(postsResp);
  }

  const copyToClipboard = () => {
    fetch(postURL)
      .then(async response => {
        const blob = await response.blob()
        await navigator.clipboard.write([new ClipboardItem({[`web ${blob.type}`]: blob})])
      })
  }

  const downloadImage = () => {
    saveAs(postURL, `e621-${post.id}.${post.file_ext}`);
    // Request getting blocked by CORS
    // fetch(postURL, {
    //   method: 'GET',
    //   mode: 'no-cors',
    // })
    //   .then(async response => {
    //     const contentType = response.headers.get('content-type')
    //     const blob = await response.blob()
    //     const file = new File([blob], `e621-${post.id}.${post.file_ext}`,
    //       {contentType})
    //     // access file here
    //     const url = URL.createObjectURL(file)
    //
    //     saveAs(url, `e621-${post.id}.${post.file_ext}`);  // Save the file using file-saver
    //   })
  }

  return (
    <div className="App">
      <header className="App-header">
        {!isLoading ? (
          <>
            {postURL && (
              <>
                {post.file_ext in ['webm', 'mp4'] ? (
                  <video controls>
                    <source src={postURL} type={`video/${post.file_ext}`}/>
                  </video>
                ) : (
                  /*<img
                    src={postURL}
                    alt="Image"
                    style={{maxHeight: '50vh', maxWidth: '50vw'}}
                  />*/
                  <LazyLoadImage
                    placeholderSrc={icon_svg}
                    src={postURL}
                    alt="Image"
                    style={{maxHeight: '50vh', maxWidth: '50vw'}}
                  />
                )}
              </>
            )}
              {post?.id ? (
                <>
                  <p style={{color: "white"}}>
                    <a href={`https://e621.net/posts/${post.id}`}>Original Post</a>{" | "}
                    <a href={`https://e621.net/users/${post.uploader_id}`}>
                      {author?.name ? author.name : "Loading..."}
                    </a><br/>
                    <span>
                    Uploaded {post?.created_at ? <ReactTimeAgo date={new Date(post?.created_at)} locale="en-US"/> : "Loading..."}
                  </span><br/>
                    <button onClick={downloadImage}>
                      Download <FontAwesomeIcon icon={faDownload}/>
                    </button>
                    {" "}
                    <button onClick={handleNext}>
                      Next <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                  </p>
                  <button onClick={() => setShowTags(!showTags)}>
                    {showTags ? "Hide" : "Show"} Tags <FontAwesomeIcon icon={showTags ? faEyeSlash : faEye}/>{' '}
                  </button>
                  {showTags && (
                    <p>{post.tag_string}</p>
                  )}
                </>
              ) : (
                <h1>Loading...</h1>
              )}
          </>
        ) : (
          <>
            <img src={icon} className="App-logo" alt="logo"/>
            <h1>Loading...</h1>
          </>
        )}
        <p className="read-the-docs">
          Made with <span role="img" aria-label="heart">❤️</span> by <a href="https://artucuno.dev">Artucuno</a>
        </p>
      </header>
    </div>
  );
}

export default App;
